.App{
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
}
.header{
    display: flex;
    align-items: center;
    padding: 22px 160px;
    z-index: 11111;
    width: calc(100% - 320px);
    position: absolute;
    justify-content: space-between;
}
.logo{
    width: 112px;
}
.check-status-btn{
    width: 164px;
    height: 46px;
    color: #66CAC8;
    border: 1px solid #66CAC8;
    border-radius: 30px;
    background: none;
    font-weight: bold;
    font-size: 16px;
}
.sign-up-head{
    width: 100%;
    height: 360px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.connect-wallet-step-container{
    background: #242A31;
    width: 100vw;
    height: 100vh;
    display: flex;
    overflow: auto;
    justify-content: center;
}
.container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.apply-app-btn{
    height: 56px;
    width: 356px;
    color: #242A31;
    background: #66CAC8;
    border: 1px solid #66CAC8;
    border-radius: 30px;
    cursor: pointer;
}
.restart-app{
    background: none;
    cursor: pointer;
    border: none;
    font-size: 16px;
    color: #66CAC8;
}
.application-title{
    font-size: 44px;
    font-weight: bold;
    color: white;
}
.how-it-works-banner{
    width: 100vw;
    background: #222a32;
    height: calc(100vh - 360px);
}
.color-white{
    color: white;
}
.how-it-works-banner{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.steps-list{
    display: flex;
    align-items: flex-start;
    width: 956px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.step-num{
    font-size: 44px;
    color: #66CAC8;
    width: 68px;
    border: 1px solid #FFDA00;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.step-title{
    font-size: 18px;
    font-weight: bold;
}
.steps-description{
    color: #8997A1;
    font-size: 14px;
    text-align: center;
}
.steps-on-main-page li{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.step-title{
    margin-bottom: 13px;
}
.step-num{
    margin-bottom: 37px;
}
.back{
    position: absolute;
    display: flex;
    align-items: center;
    left: 160px;
    top: 157px;
}
.back-icon{
    border: #1B2129 1px solid;
    background: #1B2129;
    border-radius: 50%;
    width: 32px;
    margin-right: 16px;
    cursor: pointer;
    height: 32px;
}
.back p {
    color: white;
}
.connect-wallet-block{
    width: 632px;
    height: 408px;
    background: #1B2129;
    border-radius: 12px;
    overflow: auto;
    margin-top: 157px;
    padding: 39px 118px 72px 118px;
}
.connect-wallet-block::-webkit-scrollbar{
    display: none;
}
.active{
    border-color: #66CAC8 !important;
}
.show-progress{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.show-progress li{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.hr-line{
    width: 56px;
    height: 1px;
    background: #404B53;
}
.each-step{
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: #404B53 1px solid;
    margin-bottom: 12px;

}
.each-step-title{
    color: #8997A1;
}
.active-text{
    color: white;
}
.block-title{
    font-size: 28px;
    color: white;
    margin-top: 57px;
    text-align: center;
    font-weight: bold;
}
.block-sub-title{
    color: #8997A1;
    font-size: 14px;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 64px;
}
.connect-wallet-button{
    width: 243px;
    background: none;
    height: 46px;
    border-radius: 30px;
    color: #66CAC8;
    border: 1px solid #66CAC8;
    cursor: pointer;
}
.connect-twitter-button{
    background: #4D9FEB;
    border: 1px solid #4D9FEB;
    color: white;
    border-radius: 30px;
    width: 243px;
    height: 46px;
    font-size: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
}
.categories-list{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.category-item{
    width: calc(50% - 8px);
    max-width: 260px;
    border-radius: 13px;
    margin-bottom: 16px;
    padding: 24px;
    background: #404B53;
    display: flex;
    cursor: pointer;
    align-items: center;
}
.category-item.selected{
    background: #F9DB4A !important;
}
.category-item:hover{
    background: #e7b700 !important;
}
.selected .category-title{
    color: #242A31 !important;
}
.selected .category-description{
    color: #242A31;
}
.category-title{
    color: white;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 9px;
}
.category-description{
    color: #8997A1;
    font-size: 14px;
    max-width: 226px;
}
.right-image{
    margin-left: 14px;
}
.question-count{
    font-size: 14px;
    color: #8997A1;
    margin-top: 48px;
    text-align: center;
}
.answers-text-box{
    width: 632px;
    background: #242A31;
    border: #404B53 1px solid;
    height: 130px;
    padding: 24px;
    outline: none;
    border-radius: 12px;
    color: #8997A1;
}
.flex-box-main-check-status{
    width: 100%;
    display: flex;
    justify-content: center;
}
.answers-text-box::placeholder{
    color: #8997A1;
}
.questions-actions-button{
    margin-top: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.flex-box-main{
    height: 180px !important;
}
.questions-back{
    background: none;
    font-size: 16px;
    border: none;
    color: white;
    margin-right: 32px;
}
.questions-next{
    width: 100px;
    height: 46px;
    background: #66CAC8;
    border: #66CAC8;
    border-radius: 30px;
    font-size: 16px;
    color: #242A31;
    cursor: pointer;
}
.flex-box-main-add-link{
    width: 411px !important;
    margin: auto;
}
.add-link-actions-button{
    width: 411px !important;
    margin: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.form-label{
    font-size: 14px;
    color: white;
    margin-bottom: 8px;
}
.form-field{
    width: calc(100% - 24px);
    border: 1px solid #404C53;
    height: 46px;
    border-radius: 30px;
    background: none;
    outline: none;
    padding-left: 24px;
    color: #8997A1;
    font-size: 14px;
}
.form-field::placeholder{
    color: #8997A1;
}
.link-input{
    width: 100%;
    margin-bottom: 16px;
}
.add-link-btn{
    font-size: 14px;
    color: #66CAC8;
    background: none;
    border: none;
}
.connect-wallet-block .flex-box-main-add-link{
    height: fit-content !important;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
}
button{
    cursor: pointer;
}
.banner-image-cong{
    width: 360px;
    margin: auto;
    position: absolute;
}
.congratulation-banner{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 160px;
}
.finish-duck-image{
    width: 74px;
    height: 74px;
    position: absolute;
    margin-top: 90px;
}
.congratulation-text{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 94px;
}
.cong-head{
    font-size: 28px;
    color: white;
    font-weight: bold;
}
.cong-sub-text{
    margin-top: 16px;
    font-size: 14px;
    color: #8997A1;
}
.check-status-cong{
    width: 164px;
    height: 46px;
    background: none;
    border: 1px solid #66CAC8;
    color: #66CAC8;
    font-size: 16px;
    border-radius: 30px;
    margin-top: 32px;
}
.go-to-home-page{
    margin-top: 24px;
    color: white;
    font-size: 14px;
    text-decoration: none;
}
.status-title{
    color: white;
    font-size: 14px;
}
.show-status{
    margin-top: 13px;
    font-size: 14px;
    color: #FFD17A;
    background: #302C13;
    padding: 7px 12px;
    border-radius: 30px;
}
.not-linked-wallet{
    font-size: 30px;
    color: white;
    font-weight: bold;
}
.selected-wallet-address{
    color: white;
    font-size: 17px;
}